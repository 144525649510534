const wallpaperImages = [
	{
		src: require('../images/bg/web-fatima-al-qadiri-gumar-HDB145-packshot.jpg'),
		style: {
			backgroundPosition: 'center',
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat'
		}
	},
	{
		src: require('../images/bg/mf-bg.jpg'),
		style: {
			backgroundPosition: 'center',
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat'
		}
	},
	{
		src: require('../images/bg/atlantique01_0.jpg'),
		style: {
			backgroundPosition: 'center',
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat'
		}
	},
	{
		src: require('../images/bg/shaneera-bg.jpg'),
		style: {
			backgroundPosition: 'left center',
			backgroundSize: 'contain',
			backgroundRepeat: 'repeat'
		}
	},
	{
		src: require('../images/bg/brute-bg.jpg'),
		style: {
			backgroundPosition: 'center',
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat'
		}
	},
	{
		src: require('../images/bg/asiatisch-original.jpg'),
		style: {
			backgroundPosition: 'center',
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat'
		}
	},
	{
		src: require('../images/bg/desert-strike.jpg'),
		style: {
			backgroundPosition: 'center',
			backgroundSize: 'contain',
			backgroundRepeat: 'repeat'
		}
	},
	{
		src: require('../images/bg/gsx_zoom_05.jpg'),
		style: {
			backgroundPosition: 'center',
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat'
		}
	},
	{
		src: require('../images/bg/ayshay-warn-u-1440.jpg'),
		style: {
			backgroundPosition: 'center',
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat'
		}
	},
	{
		src: require('../images/bg/muslim-trance-tile.jpg'),
		style: {
			backgroundSize: 'contain',
			backgroundRepeat: 'repeat'
		}
	},
	{
		src: require('../images/bg/skincare-bg-01.jpg'),
		style: {
			backgroundPosition: 'center',
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat'
		}
	}


];

export { wallpaperImages };
