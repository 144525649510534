import React, { useEffect, useState } from 'react';
import { wallpaperImages } from '../data/wallpaperImages';

const WallpaperBackground: React.FC = () => {
  // Manually set the starting index to a specific image, e.g., index 2
  const [currentImageIndex, setCurrentImageIndex] = useState(10);
  const [fade, setFade] = useState(false);

  useEffect(() => {
    setFade(true);

    const interval = setInterval(() => {
      setFade(false); // Fade out
      setTimeout(() => {
        let nextIndex;
        do {
          nextIndex = Math.floor(Math.random() * wallpaperImages.length);
        } while (nextIndex === currentImageIndex); // Ensure the next index is different

        setCurrentImageIndex(nextIndex);
        setFade(true); // Fade in
      }, 2000); // Match this with transition-duration
    }, 15000); // Total cycle duration

    return () => clearInterval(interval);
  }, [currentImageIndex]);

  return (
    <div className="fixed inset-0 z-0 overflow-hidden">
      {wallpaperImages.map((image, index) => (
        <div
          key={index}
          className="absolute inset-0 transition-opacity duration-2000"
          style={{
            backgroundImage: `url(${image.src})`,
            ...image.style,
            opacity: index === currentImageIndex ? (fade ? 1 : 0) : 0,
            display: index === currentImageIndex || index === (currentImageIndex + 1) % wallpaperImages.length ? 'block' : 'none',
          }}
        />
      ))}
    </div>
  );
};

export default WallpaperBackground;
